import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";

const Page = styled.div`
  margin: 1rem;
`;

const PageTitle = styled.h1``;

const PageContent = styled.main``;

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data;
  return (
    <Layout noSidebar={true}>
      <Helmet title={`${post.frontmatter.title}`} />
      <Page>
        <PageTitle>{post.frontmatter.title}</PageTitle>
        <PageContent dangerouslySetInnerHTML={{ __html: post.html }} />
      </Page>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ListByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
